define(['app', '$window'], (app, $window) => {
  const productDigitalAndPhysicalOptions = () => {
    const component = {};

    const _config = {
      selectors: {
        optionsList: '[data-js-element="productDigitalAndPhysicalOptions_list"]',
        radioSelector: '[data-radio]',
        optionProductId: 'data-option-id',
        optionPropertyKey: 'data-property-key',
      },
      subscribeChannels: {
        image: 'productSingleImage/newImage',
        imageMultiple: 'productMultipleImages/newImage',
        fullDescription: 'fullProductDescription/new',
        productReviews: 'productReviews/newProductReviews',
        productName: 'productName/update',
        productReviewStars: 'productReviewStars/update',
        price: 'productPrice/newPrice',
        stock: 'productStockInformation/newStockInformation',
        releaseDate: 'productReleaseDate/newReleaseDate',
        addToBasketButton: 'productAddToBasketButton/newButton',
        athenaProductFreeDelivery: 'athenaProductFreeDelivery/update',
        recentlyViewed: 'productRecentlyViewed/update',
        productFrequentlyBoughtTogether: 'productFrequentlyBoughtTogether/update',
        instalmentInfo: 'productInstalmentPayment/newInstalmentInfo',
        BNPLProvidersInfo: 'productBuyNowPayLaterProviders/providersInfo'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.app = app;

      component.optionsList = component.element.querySelector(component.config.selectors.optionsList);
      component.productId = component.optionsList.getAttribute(component.config.selectors.productId);
      component.radios = [...component.element.querySelectorAll(component.config.selectors.radioSelector)];

      component.addEventListeners();

      return component;
    };

    const _addEventListeners = () => {
      component.radios.forEach(radio => {
        radio.addEventListener('change', component.radioChange);
      });
    };

    const _radioChange = (ev) => {
      const radio = ev.currentTarget;
      $window.scrollTo(0, 0);

      if (!radio.checked) {
        return;
      }

      const selectedProduct = radio.getAttribute(component.config.selectors.optionProductId);
      const key = radio.getAttribute(component.config.selectors.optionPropertyKey);

      component.changeProductImage(selectedProduct);
      component.updateFullProductDescription(selectedProduct);
      component.updateReviews(selectedProduct);
      component.updateProductName(selectedProduct);
      component.updateReviewStars(selectedProduct);
      component.changeProductPrice(selectedProduct);
      component.changeProductStockInformation(selectedProduct);
      component.changeReleaseDate(selectedProduct);
      component.changeAddToBasketButton(selectedProduct);
      component.updateAthenaProductFreeDelivery(key);
      component.changeProductRecentlyViewed(selectedProduct);
      component.changeProductFrequentlyBoughtTogether(selectedProduct);
      component.changeProductInstalmentPayment(selectedProduct);
      component.changeProductBNPLProviders(selectedProduct);
    };

    const _changeProductImage = (skuId) => {
      app.publish(component.config.subscribeChannels.image, skuId, true);
      app.publish(component.config.subscribeChannels.imageMultiple, {
        productId: skuId,
        variation: false
      });
    };

    const _updateFullProductDescription = (skuId) => {
      app.publish(component.config.subscribeChannels.fullDescription, skuId, true);
    };

    const _updateReviews = (skuId) => {
      app.publish(component.config.subscribeChannels.productReviews, skuId, true);
    };

    const _updateProductName = (skuId) => {
      app.publish(component.config.subscribeChannels.productName, skuId, true);
    };

    const _updateReviewStars = (skuId) => {
      app.publish(component.config.subscribeChannels.productReviewStars, skuId, true);
    };

    const _changeProductPrice = (skuId) => {
      app.publish(component.config.subscribeChannels.price, skuId, true);
    };

    const _changeProductInstalmentPayment = (skuId) => {
      app.publish(component.config.subscribeChannels.instalmentInfo, skuId, true);
    };

    const _changeProductBNPLProviders = (skuId) => {
      app.publish(component.config.subscribeChannels.BNPLProvidersInfo, skuId, true);
    };

    const _changeProductStockInformation = (skuId) => {
      app.publish(component.config.subscribeChannels.stock, skuId, true);
    };

    const _changeReleaseDate = (skuId) => {
      app.publish(component.config.subscribeChannels.releaseDate, skuId, true);
    };

    const _changeAddToBasketButton = (skuId) => {
      app.publish(component.config.subscribeChannels.addToBasketButton, skuId);
    };

    const _updateAthenaProductFreeDelivery = (propertyKey) => {
      app.publish(component.config.subscribeChannels.athenaProductFreeDelivery, propertyKey, true);
    };

    const _changeProductRecentlyViewed = (skuId) => {
      app.publish(component.config.subscribeChannels.recentlyViewed, skuId, true);
    };

    const _changeProductFrequentlyBoughtTogether = (skuId) => {
      app.publish(component.config.subscribeChannels.productFrequentlyBoughtTogether, skuId, true);
    };


    component.config = _config;
    component.init = _init;

    component.radioChange = _radioChange;
    component.addEventListeners = _addEventListeners;
    component.changeProductImage = _changeProductImage;
    component.updateFullProductDescription = _updateFullProductDescription;
    component.updateReviews = _updateReviews;
    component.updateProductName = _updateProductName;
    component.updateReviewStars = _updateReviewStars;
    component.changeProductPrice = _changeProductPrice;
    component.changeAddToBasketButton = _changeAddToBasketButton;
    component.changeProductStockInformation = _changeProductStockInformation;
    component.updateAthenaProductFreeDelivery = _updateAthenaProductFreeDelivery;
    component.changeReleaseDate = _changeReleaseDate;
    component.changeProductRecentlyViewed = _changeProductRecentlyViewed;
    component.changeProductFrequentlyBoughtTogether = _changeProductFrequentlyBoughtTogether;
    component.changeProductInstalmentPayment = _changeProductInstalmentPayment;
    component.changeProductBNPLProviders = _changeProductBNPLProviders;

    return component;
  };

  return productDigitalAndPhysicalOptions;
});
